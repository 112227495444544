var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: { height: "100%", width: "100%" },
      attrs: { id: "REBATE_BADGE_GROUP_MODAL" },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            "footer-class": "footerClass",
            title: "Edit Assigned Rebate",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            scrollable: "",
          },
          on: { hide: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-form-group",
            [
              _c("label", { staticClass: "mr-1" }, [
                _vm._v(_vm._s(_vm.$t(`project.field.rebates`))),
              ]),
              _c(
                "button",
                {
                  staticClass: "btn-action",
                  on: { click: _vm.rebateSelectorToggle },
                },
                [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
                1
              ),
              _c("BadgeGroup", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ item, index }) {
                      return [
                        _c("Badge", {
                          key: index,
                          attrs: {
                            text: `${item.name} ${_vm.formatRebate(
                              item.rebate
                            )}`,
                            variant: "primary",
                            pillable: !!item.pillable,
                          },
                          on: {
                            badgeRemove: function ($event) {
                              return _vm.rebateBadgeRemove(index)
                            },
                            badgeClick: function ($event) {
                              return _vm.rebateBadgeClick(item.uuId)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.rebates,
                  callback: function ($$v) {
                    _vm.rebates = $$v
                  },
                  expression: "rebates",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm.rebateSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.rebateSelectorShow,
              entityService: _vm.rebateUtil,
              entity: "REBATE",
              preselected: _vm.rebateEditId,
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.rebateSelectorShow = $event
              },
              cancel: _vm.rebateSelectorCancel,
              ok: _vm.rebateSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }