var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.title,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        size: "sm",
                        disabled: !_vm.isNameValid || !_vm.epoch,
                        variant: "success",
                      },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.state.errorShow,
                callback: function ($$v) {
                  _vm.$set(_vm.state, "errorShow", $$v)
                },
                expression: "state.errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("b-form-input", {
            attrs: {
              maxlength: "60",
              readonly: _vm.sharing,
              autofocus: !_vm.sharing,
              trim: "",
            },
            nativeOn: {
              keydown: function ($event) {
                return _vm.keydown_handler.apply(null, arguments)
              },
            },
            model: {
              value: _vm.saveName,
              callback: function ($$v) {
                _vm.saveName = $$v
              },
              expression: "saveName",
            },
          }),
          !_vm.sharing
            ? _c(
                "b-form-group",
                { attrs: { label: _vm.$t("epoch.date"), "label-for": "name" } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-datepicker", {
                        staticClass: "mb-2",
                        attrs: {
                          id: "activity-startDate",
                          "today-button": "",
                          "reset-button": "",
                          "close-button": "",
                          "hide-header": "",
                          boundary: "viewport",
                          min: _vm.minDate,
                          max: _vm.maxDate,
                          "popper-opts": { positionFixed: true },
                          "label-today-button": _vm.$t("date.today"),
                          "label-reset-button": _vm.$t("date.reset"),
                          "label-close-button": _vm.$t("date.close"),
                          "today-button-variant": "primary",
                          "reset-button-variant": "danger",
                          "close-button-variant": "secondary",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "button-content",
                              fn: function (ref) {
                                return [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "calendar-days"] },
                                  }),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2621928167
                        ),
                        model: {
                          value: _vm.epoch,
                          callback: function ($$v) {
                            _vm.epoch = $$v
                          },
                          expression: "epoch",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.sharing
            ? _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-group",
                          {
                            staticClass: "mt-2 d-inline-block",
                            attrs: {
                              label: _vm.$t("dataview.field.sharing"),
                              "label-for": "dataview-visibility",
                            },
                          },
                          [
                            _c("b-form-radio-group", {
                              staticClass: "radio-group-class",
                              attrs: { options: _vm.sharingOptions },
                              model: {
                                value: _vm.sharedVisibility,
                                callback: function ($$v) {
                                  _vm.sharedVisibility = $$v
                                },
                                expression: "sharedVisibility",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-button",
                          {
                            staticClass: "sharing-members d-inline-block",
                            attrs: {
                              disabled: _vm.sharedVisibility === "public",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.editSharingMembers()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.members")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("b-col", [
                    _c(
                      "div",
                      [
                        _c("label", { staticClass: "mt-2 d-block" }, [
                          _vm._v(
                            _vm._s(_vm.$t("dataview.field.editing_permissions"))
                          ),
                        ]),
                        _c(
                          "b-button",
                          {
                            staticClass: "d-inline-block",
                            attrs: { size: "sm" },
                            on: {
                              click: function ($event) {
                                return _vm.editPermissions()
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("button.members")))]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("MembersModal", {
        attrs: {
          show: _vm.showSharing,
          members: _vm.sharingMembers,
          title: _vm.$t("dataview.select_members_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showSharing = $event
          },
          success: _vm.membersSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          show: _vm.showPermissions,
          members: _vm.editingPermissions,
          title: _vm.$t("dataview.select_members_editing_title"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showPermissions = $event
          },
          success: _vm.permissionsSelectOk,
        },
      }),
      _c("MembersModal", {
        attrs: {
          show: _vm.showUpdateUsers,
          members: _vm.updateUsers,
          title: _vm.$t("user.select_users_to_update"),
        },
        on: {
          "update:show": function ($event) {
            _vm.showUpdateUsers = $event
          },
          success: _vm.updateUsersSelectOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }