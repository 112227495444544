var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.title,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { hide: _vm.hide },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: {
                          click: function ($event) {
                            return _vm.Ok(true)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.hide },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "SELECT" === _vm.mode
                              ? "button.close"
                              : "button.cancel"
                          )
                        )
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c("TagList", {
            attrs: {
              tags: _vm.tagData,
              enableEntityOptions: _vm.enableEntityOptions,
            },
            on: { modified: _vm.tagsModified },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }