<template>
  <div>
    <b-modal v-model="modalShow" size="md" :title="title" footer-class="footerClass"
      @hide="hide"
      content-class="shadow" no-close-on-backdrop
    >
    
      <TagList :tags="tagData" @modified="tagsModified" :enableEntityOptions="enableEntityOptions"/>
      
      <template v-slot:modal-footer="{}">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <template>
          <b-button size="sm" variant="success" @click="Ok(true)">{{ $t('button.ok') }}</b-button>
        </template>
        <b-button size="sm" variant="danger" @click="hide">{{ $t('SELECT' === mode? 'button.close':'button.cancel') }}</b-button>
        
      </template>
    </b-modal>
    
  </div>
</template>

<script>

export default {
  name: 'TagWithListModal',
  components: {
    TagList: () => import('@/components/Tag/TagList.vue')
  },
  props: {
    title:        { type: String,   default: function() { return this.$t('tag.edit_tags'); } },
    show:         { type: Boolean, required: true },
    mode:         { type: String, default: 'BOTH' }, //Possible value: ['SELECT', 'MANAGE', 'BOTH'],
    tags:        { type: Array, default: () => { return []; } },
    enableEntityOptions: { type: Boolean, default: false }
  },
  data() {
    return {
      modalShow: false,
      tagData: []
    }
  },
  created() {
    this.tagData = Array.isArray(this.tags)? this.tags.map(t => { return { name: t }}) : [];
    this.modalShow = this.show;
  },
  watch: {
    show(newValue) {
      if(newValue != this.modalShow) {
        this.tagData = Array.isArray(this.tags)? this.tags.map(t => { return { name: t }}) : [];
        this.modalShow = newValue;
      }
    }
  },
  methods: {
    Ok() {
      this.$emit('update:show', false);
      this.$emit('changed', this.tagData.map(t => { return t.name }));
    },
    tagsModified({tags}) {
      this.tagData = tags;
    },
    hide() {
      this.$emit('update:show', false);
      this.$emit('cancel');
    }

  }
}
</script>

<style lang="scss">

</style>