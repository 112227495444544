<template>
  <div style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="lg" :title="title" footer-class="footerClass" 
      no-close-on-backdrop  content-class="shadow"
      @hidden="hidden">

      <b-alert variant="danger" dismissible v-model="state.errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ alertMsg }} 
      </b-alert>

      <b-form-input
        v-model="saveName"
        maxlength="60"
        :readonly="sharing"
        @keydown.native="keydown_handler"
        :autofocus="!sharing"
        trim>
      </b-form-input>
      
      <b-form-group v-if="!sharing" :label="$t('epoch.date')" label-for="name">
        <b-input-group>
          <b-form-datepicker id="activity-startDate" v-model="epoch" class="mb-2"
            today-button
            reset-button
            close-button
            hide-header
            boundary="viewport"
            :min="minDate"
            :max="maxDate"
            :popper-opts="{ positionFixed: true }"
            :label-today-button="$t('date.today')"
            :label-reset-button="$t('date.reset')"
            :label-close-button="$t('date.close')"
            today-button-variant="primary"
            reset-button-variant="danger" 
            close-button-variant="secondary"
          >
            <template v-slot:button-content="{ }">
              <font-awesome-icon :icon="['far', 'calendar-days']" />
            </template>
          </b-form-datepicker>
        </b-input-group>
      </b-form-group>
        
      <b-row v-if="sharing">
        <b-col>
          <div>     
            <b-form-group class="mt-2 d-inline-block" :label="$t('dataview.field.sharing')" label-for="dataview-visibility">     
              <b-form-radio-group
                class="radio-group-class"
                v-model="sharedVisibility"
                :options="sharingOptions">
              </b-form-radio-group>
            </b-form-group>
            <b-button :disabled="sharedVisibility === 'public'" class="sharing-members d-inline-block" size="sm" @click="editSharingMembers()">{{ $t('button.members') }}</b-button>
          </div>
        </b-col>
        <b-col>
          <div>     
            <label class="mt-2 d-block">{{ $t('dataview.field.editing_permissions') }}</label>
            <b-button class="d-inline-block" size="sm" @click="editPermissions()">{{ $t('button.members') }}</b-button>
          </div>
        </b-col>
      </b-row>
          
      <template v-slot:modal-footer="{}">
        <b-button size="sm" :disabled="!isNameValid || !epoch" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <MembersModal :show.sync="showSharing" :members="sharingMembers" :title="$t('dataview.select_members_title')" @success="membersSelectOk"/>
    <MembersModal :show.sync="showPermissions" :members="editingPermissions" :title="$t('dataview.select_members_editing_title')" @success="permissionsSelectOk"/>    
    <MembersModal :show.sync="showUpdateUsers" :members="updateUsers" :title="$t('user.select_users_to_update')" @success="updateUsersSelectOk"/>   
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { labelProfileService, companyService } from '@/services';

export default {
  name: 'SaveLabelModal',
  components: {
    MembersModal:  () => import('@/components/modal/MembersModal')
  },
  props: {
    name: {
      type: String,
      default: null
    },
    profile: {
      type: Object,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    sharing: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
    }
  },
  data() {
    return {
      state: {
        modalShow: false,
        errorShow: false,
      },
      alertMsg: null,
      uuId: null,
      profileData: null,
      userId: null,
      saveName: null,
      sharedVisibility: 'private',
      showSharing: false,
      sharingMembers: "",
      showPermissions: false,
      showUpdateUsers: false,
      editingPermissions: "",
      updateUsers: "",
      defaultView: false,
      epoch: null
    }
  },
  created() {
    this.userId = this.$store.state.authentication.user.uuId;
    this.updateShow(this.show);
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.uuId = this.profile.uuId;
        this.profileData = cloneDeep(this.profile);
        this.saveName = cloneDeep(this.name);
        this.sharedVisibility = this.profileData.sharedVisibility ? cloneDeep(this.profileData.sharedVisibility) : 'private';
        this.sharingMembers = this.profileData.sharingMembers ? cloneDeep(this.profileData.sharingMembers) : '';
        this.editingPermissions = typeof this.profileData.editingPermissions !== 'undefined' ? cloneDeep(this.profileData.editingPermissions) : this.profileData.sharingMembers ? cloneDeep(this.profileData.sharingMembers) : '';
        this.epoch = this.profileData.epoch;
      }
      this.updateShow(newValue);
    }
  },
  computed: {
    maxDate() {
      return new Date();
    },
    minDate() {
      return "2024-01-01";
    },
    isNameValid() {
      return this.saveName != null && this.saveName.trim().length > 0;
    },
    sharingOptions() {
      return [{text: this.$t('dataview.public'), value: 'public'},
              {text: this.$t('dataview.private'), value: 'private'}]
    }
  },
  methods: { 
    keydown_handler(event) {
       if (event.which === 13) {
          // The key pressed was the enter key
          if (this.isNameValid) {
            this.ok();
          }
       }
    },
    async ok() {
      if (!this.saveName) {
        return;
      }
      
      this.profileData.sharedVisibility = this.sharedVisibility;
      this.profileData.sharingMembers = this.sharingMembers;
      this.profileData.editingPermissions = this.editingPermissions;
      this.profileData.name = this.saveName;
      this.profileData.defaultView = this.defaultView;
      this.profileData.epoch = this.epoch;
      
      if (!this.uuId) {
        this.createViewProfile();
      }
      else {
        this.updateViewProfile();
      }

      await this.updateSharedVisibility();
      
      const existingIds = this.profile !== null &&
                          typeof this.profile.sharingMembers !== 'undefined' &&
                          this.profile.sharingMembers !== ""
                           ? this.profile.sharingMembers.split(',') : [];
      const sharedIds = this.sharingMembers && this.sharingMembers !== "" ? this.sharingMembers.split(',') : [];
      // add share
      for (var shareToId of sharedIds) {
        const exists = existingIds.filter(e => e === shareToId);
        if (exists.length === 0 && shareToId !== this.userId) {
          await this.shareViewProfile(shareToId);
        }
      }
      
      // remove unshared
      for (var existingId of existingIds) {
        const exists = sharedIds.filter(e => e === existingId);
        if (exists.length === 0) {
          await this.unshareViewProfile(existingId);
        }
      }      
  
      this.state.errorShow = false;
      this.$emit('ok', { name: this.saveName, profile: this.profileData, newDefault: this.profile.defaultView !== this.profileData.defaultView && this.profileData.defaultView, updateUsers: this.updateUsers, sharing: this.sharing });
      this.$emit('update:show', false);
    },   
    async updateSharedVisibility() {
      if (this.profile.sharedVisibility && this.profile.sharedVisibility !== this.sharedVisibility) {
        const company = await companyService.list({limit: -1, start: 0}).then((response) => {
          const data = response.data;
          return data.filter(d => d.type === 'Primary');
        })
        .catch((e) => {
          console.log(e); // eslint-disable-line no-console
          return null;
        });
        
        if (company.length > 0) {
          if (this.sharedVisibility === 'private') {
            this.unshareViewProfile(company[0].uuId, true);
          }
          else {
            this.shareViewProfile(company[0].uuId, true);
          }
        }
      }
    }, 
    async shareViewProfile(shareToId, isPublic = false) {
        await labelProfileService.share(this.uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    async unshareViewProfile(shareToId, isPublic = false) {
        await labelProfileService.unshare(this.uuId, shareToId, isPublic).then((response) => {  
        const data = response.data[response.data.jobCase];
        return data;
      })
      .catch((e) => {
        console.log(e); // eslint-disable-line no-console
      });
    },
    createViewProfile() {
      labelProfileService.create([this.profileData], this.userId).then((response) => {
        const data = response.data[response.data.jobCase];
        this.uuId = this.profileData.uuId = data[0].uuId;
      })
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    updateViewProfile() {
      labelProfileService.update([this.profileData], this.userId)
      .catch((e) => {
        console.error(e); // eslint-disable-line no-console
      });
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    hidden() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    updateShow(newValue) {
      this.state.modalShow = newValue;
    },    
    editSharingMembers() {
      this.state.errorShow = false;
      this.showSharing = true;
    },
    editPermissions() {
      this.state.errorShow = false;
      this.showPermissions = true;
    },
    getUpdateUsers() {
      this.showUpdateUsers = true;
    },
    membersSelectOk(members) {
      if (members === "") {
        this.alertMsg = this.$t('dataview.error.members_empty');
        this.state.errorShow = true;
        return;
      }
      this.sharingMembers = members;
      
      const permissionArray = this.editingPermissions ? this.editingPermissions.split(',') : [];
      for (var permission of permissionArray) {
        if (!this.sharingMembers.includes(permission)) {
          const permissions = this.editingPermissions.split(',');
          const index = permissions.indexOf(permission);
          permissions.splice(index, 1);
          this.editingPermissions = permissions.join(',');  
        }
      }
    },
    permissionsSelectOk(permissions) {
      if (permissions === "") {
        this.alertMsg = this.$t('dataview.error.permissions_empty');
        this.state.errorShow = true;
        return;
      }
      this.editingPermissions = permissions;
      const permissionArray = permissions.split(',');
      for (var permission of permissionArray) {
        if (permission !== this.userId &&
            !this.sharingMembers.includes(permission)) {
          this.sharingMembers === "" ? this.sharingMembers = permission : this.sharingMembers += `,${permission}`;   
        }
      }
    },
    updateUsersSelectOk(users) {
      this.updateUsers = users;
    }
  }
}
</script>
<style lang="scss">

</style>